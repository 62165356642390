import './style.css'

import { useMutation } from '@apollo/react-hooks'
import { ReactComponent as Clinic } from '@medical/assets/image/clinic.svg'
import BreadCrumb from '@medical/components/Breadcrumb'
import { PERMANENT_DOCTOR, REGULAR_SHIFT_DOCTOR } from '@medical/constant'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import Router from '@medical/routes/router'
import { Col, Row } from 'antd'
import { Pagination } from 'antd'
import { Form, Input, Select } from 'antd'
import { Button } from 'primereact/button'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const RecruitmentPostsScene = ({
  i18n,

  edges = [],

  rowsPerPage,

  page,

  count,

  onPageChange,

  workPattern,

  content_contains,

  title,

  clinicalDepartments,

  clinicalDepartmentIds = [],

  history,

  departmentNames,
}) => {
  const [listData, setListData] = useState([])

  const [workPatternItem, setWorkPatternItem] = useState(workPattern)

  const [listclinicalDepartmentIds, setListClinicalDepartmentIds] = useState(
    clinicalDepartmentIds
  )

  const [content_contains_item, setContentContains] = useState(content_contains)

  const getContentContains = e => {
    setContentContains(e.target.value)
  }

  const renderStatus = status => {
    switch (status) {
      case 'NOT_APPLY':
        return <span style={{ color: '#ff518f' }}>未応募</span>

      case 'APPLIED':
        return <span style={{ color: '#369efd' }}>応募中</span>

      case 'COMPLETED':
        return <span style={{ color: '#647380' }}>対応終了</span>
    }
  }

  const getDataClinicalDepartments = e => {
    setListClinicalDepartmentIds(e)
  }

  const getDataWorkPattern = e => {
    setWorkPatternItem(e)
  }

  const onFinish = values => {
    history.push({
      pathname: Router.doctorRecruitments,

      search: queryString.stringify({
        rowsPerPage,

        title,

        workPattern: workPatternItem,

        content_contains: content_contains_item,

        clinicalDepartmentIds: listclinicalDepartmentIds

          ?.map(item => item)

          .join('＿'),
      }),
    })
  }

  const { TextArea } = Input

  const [form] = Form.useForm()

  const { Option } = Select

  const [downloadFile] = useMutation(DOWNLOAD_FILE)

  const downloadFileSubmit = async key => {
    if (!key || key.length === 0) return null

    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })

      return fileURL
    } catch (error) {
      return null
    }
  }

  const workPatterns = [
    { value: PERMANENT_DOCTOR, description: '常勤' },

    { value: REGULAR_SHIFT_DOCTOR, description: '定期非常勤' },
  ]

  useEffect(() => {
    const fetchData = async () => {
      const promises = []

      for (const item of edges) {
        if (item.node.imageKey) {
          promises.push(downloadFileSubmit(item.node.imageKey))
        }
        if (!item.node.imageKey) {
          promises.push(downloadFileSubmit(process.env.REACT_APP_IMAGE_DEFAULT))
        }
      }

      const results = await Promise.all(promises)

      const temp = edges.map((el, index) => ({
        ...el,

        img: results[index],
      }))

      setListData(temp)
    }

    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onReset = () => {
    form.resetFields()
  }

  return (
    <React.Fragment>
      <div
        className='container-posts-scene container-posts-scene-100 '
        style={{ width: '70%' }}
      >
        <div className='staff-header'>
          <div className='staff-title'>
            {i18n.t('doctor.menuBar.recruitment')}
            <BreadCrumb
              items={[{ title: i18n.t('doctor.menuBar.recruitment') }]}
            />
          </div>
        </div>

        <div className='staff-search' style={{ marginBottom: '30px' }}>
          <div className='staff-search-wrap'>
            <Form
              form={form}
              colon={false}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              name='control-hooks'
              onFinish={onFinish}
            >
              <Row>
                <Col lg={24} sm={24} xs={18}>
                  <Form.Item
                    name='clinicName'
                    label={
                      <p
                        style={{
                          fontSize: '16px',

                          fontWeight: 'bold',

                          marginTop: '15px',
                        }}
                      >
                        クリニックから検索
                      </p>
                    }
                    wrapperCol={{
                      offset: 0,

                      span: 8,
                    }}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      showArrow
                      placeholder='クリニック選択(複数選択可)'
                      mode='multiple'
                      onChange={getDataClinicalDepartments}
                      defaultValue={clinicalDepartmentIds}
                    >
                      {clinicalDepartments.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.clinicName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col lg={24} sm={24} xs={18}>
                  <Form.Item
                    // name='応募区分'

                    name='workPattern'
                    label={
                      <p
                        style={{
                          fontSize: '16px',

                          fontWeight: 'bold',

                          marginTop: '15px',
                        }}
                      >
                        応募区分
                      </p>
                    }
                    placeholder
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    wrapperCol={{
                      offset: 0,

                      span: 8,
                    }}
                  >
                    <Select
                      placeholder='勤務種別を選択'
                      onChange={getDataWorkPattern}
                      defaultValue={workPattern}
                    >
                      {workPatterns.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.description}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                style={{ marginBottom: '8px' }}
                name='clinicContent'
                label={
                  <p
                    style={{
                      fontSize: '16px',

                      fontWeight: 'bold',

                      marginTop: '15px',
                    }}
                  >
                    フリーワード
                  </p>
                }
                wrapperCol={{
                  offset: 0,

                  span: 16,
                }}
              >
                <TextArea
                  defaultValue={content_contains}
                  onChange={getContentContains}
                  autoSize={{
                    minRows: 2,

                    maxRows: 3,
                  }}
                  placeholder='例：「急募」「月給相談可」「即勤務可」…等'
                />

                <span className='note-status'>
                  <p
                    style={{
                      color: 'var(--primary)',

                      display: 'inline-block',

                      padding: '0px',
                    }}
                  >
                    ※
                  </p>
                  募集要項に記載されている文言から検索ができます
                </span>
              </Form.Item>

              <Row>
                <Col lg={24} sm={24} xs={24}>
                  <Form.Item
                    className='form-button-search'
                    style={{ justifyContent: 'end' }}
                  >
                    <div className='staff-search-button'>
                      <Link
                        to={{
                          pathname: Router.doctorRecruitments,

                          search: queryString.stringify({
                            rowsPerPage,
                          }),
                        }}
                      >
                        <Button
                          className='cancel-button'
                          onClick={onReset}
                          label='クリア '
                          style={{
                            marginRight: '15px',
                          }}
                        />
                      </Link>

                      <Button
                        htmlType='submit'
                        className='reflect-button'
                        label='検索'
                        style={{
                          marginLeft: '15px',
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>

        <div
          className='box'
          style={{
            boxShadow: 'none',

            display: 'flex',

            flexDirection: 'column',

            justifyContent: 'center',
          }}
        >
          {edges.length === 0 && (
            <p style={{ margin: 'auto' }}>掲載がありません</p>
          )}

          <div className=''>
            <Row>
              <Col
                className='gutter-row note-status-wrap'
                lg={{
                  span: 24,

                  offset: 0,
                }}
                xs={{
                  span: 24,

                  offset: 0,
                }}
                md={{
                  span: 24,

                  offset: 0,
                }}
              >
                <span className='note-status-2'>
                  <p
                    style={{
                      color: 'var(--primary)',

                      display: 'inline-block',
                    }}
                  >
                    ※
                  </p>
                  事務局へのお問い合わせは、各募集要項の詳細からお問い合わせボタンをクリックしてください。
                </span>
              </Col>
            </Row>

            {listData.map((item, index) => {
              return (
                <div
                  className='recruitment-item'
                  style={{ marginBottom: '25px' }}
                >
                  <div className='recruitment-item-img-wrap'>
                    <Link
                      to={Router.get(Router.doctorRecruitmentDetail, {
                        recruitmentId: item.node.id,
                      })}
                      className='recruitment-img-link'
                    >
                      <img src={item.img} className='recruitment-img' />
                    </Link>
                  </div>
                  <div className='recruitment-item-content'>
                    <div className='recruitment-item-content-status'>
                      <span className='recruitment_type-link'>
                        {item.node.recruitmentDoctorType === 'PERMANENT_DOCTOR'
                          ? '常勤'
                          : '定期非常勤'}
                      </span>

                      {item.node?.clinicalDepartment && (
                        <span style={{ marginLeft: '10px' }}>
                          {renderStatus(item.node.status)}
                        </span>
                      )}
                      <Link
                        to={Router.get(Router.doctorRecruitmentDetail, {
                          recruitmentId: item.node.id,
                        })}
                      >
                        <h2 className='recruitment-title'>{item.node.title}</h2>
                      </Link>
                      <p className='recruitment-content-post'>
                        {item.node.content.replace(/<[^>]+>/g, '')}
                      </p>
                    </div>

                    {item.node?.clinicalDepartment && (
                      <div
                        className='recruitment_subtitle'
                        style={{ display: 'flex', color: 'black' }}
                      >
                        <i
                          style={{
                            marginRight: '5px',

                            marginBottom: '-3px',
                          }}
                          className='bg-red mt-0'
                        >
                          <Clinic />
                        </i>

                        {`${item.node.clinicalDepartment.clinic.name}_${
                          departmentNames[item.node.clinicalDepartment.name]
                        }`}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <Pagination
            total={count}
            size='small'
            pageSize={rowsPerPage}
            current={parseInt(page)}
            onChange={onPageChange}
            style={{ margin: '50px auto' }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default RecruitmentPostsScene
