import gql from 'graphql-tag'

export const CLINIC_FRAGMENT = gql`
    fragment CLINIC_FRAGMENT on Clinic {
        id
        name
        nameKana
        address
        phoneNumber
        email
        isPublished
        clinicalDepartments {
          id
          name
        }
    }
`

export const CLINICAL_DEPARTMENTS = gql`
  mutation ClinicalDepartments($id: ID) {
    clinicalDepartments(where: { Clinic: { id: $id} }) {
      id,
      name,
      departmentCode
    }
  }
`

export const CLINICS_CONNECTION = gql`
  query CLINICS_CONNECTION($where: ClinicWhereInput, $skip: Int, $first: Int) {
    clinicsConnection(where: $where, skip: $skip, first: $first) {
      edges {
        node {
          ...CLINIC_FRAGMENT
        }
      }
    }
  }
  ${CLINIC_FRAGMENT}
`
export const CREATE_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN = gql `
  mutation CreateExpectationDoubleRecruitmentRecordPattern(
    $targetMonth: Int!,
    $targetYear: Int!,
    $data: [CustomExpectationDoubleRecruitmentRecordPatternInput]
  ) {
    createExpectationDoubleRecruitmentRecordPattern(
      targetMonth: $targetMonth
      targetYear: $targetYear
      data: $data
    )
  }
`

export const GET_LIST_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN = gql `
  mutation GetListExpectationDoubleRecruitmentRecordPattern(
    $targetYear: Int,
    $targetMonth: Int,
  ) {
    getListExpectationDoubleRecruitmentRecordPattern(targetYear: $targetYear, targetMonth: $targetMonth) {
      updatedAt
      createdAt
      id
      targetMonth
      targetYear
      mondayMorningShiftDoctors
      mondayAfternoonShiftDoctors
      mondayNightShiftDoctors
      tuesdayMorningShiftDoctors
      tuesdayAfternoonShiftDoctors
      tuesdayNightShiftDoctors
      wednesdayMorningShiftDoctors
      wednesdayAfternoonShiftDoctors
      wednesdayNightShiftDoctors
      thursdayMorningShiftDoctors
      thursdayAfternoonShiftDoctors
      thursdayNightShiftDoctors
      fridayMorningShiftDoctors
      fridayAfternoonShiftDoctors
      fridayNightShiftDoctors
      saturdayMorningShiftDoctors
      saturdayAfternoonShiftDoctors
      saturdayNightShiftDoctors
      sundayMorningShiftDoctors
      sundayAfternoonShiftDoctors
      sundayNightShiftDoctors
      holidayMorningShiftDoctors
      holidayAfternoonShiftDoctors
      holidayNightShiftDoctors
      Clinic_ExpectationDoubleRecruitmentRecordPattern_clinicToClinic {
        id
        name
        clinicalDepartments {
          id
          name
        }
      }
    }
  }
`

export const GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN = gql `
  mutation GetExpectationDoubleRecruitmentRecordPattern(
    $clinic: ID!,
    $clinicalDepartment: ID!,
    $targetMonth: Int,
    $targetYear: Int,
  ) {
    getExpectationDoubleRecruitmentRecordPattern(
      clinic: $clinic
      clinicalDepartment: $clinicalDepartment
      targetMonth: $targetMonth
      targetYear: $targetYear
    ) {
      updatedAt
      createdAt
      id
      targetMonth
      targetYear
      mondayMorningShiftDoctors
      mondayAfternoonShiftDoctors
      mondayNightShiftDoctors
      tuesdayMorningShiftDoctors
      tuesdayAfternoonShiftDoctors
      tuesdayNightShiftDoctors
      wednesdayMorningShiftDoctors
      wednesdayAfternoonShiftDoctors
      wednesdayNightShiftDoctors
      thursdayMorningShiftDoctors
      thursdayAfternoonShiftDoctors
      thursdayNightShiftDoctors
      fridayMorningShiftDoctors
      fridayAfternoonShiftDoctors
      fridayNightShiftDoctors
      saturdayMorningShiftDoctors
      saturdayAfternoonShiftDoctors
      saturdayNightShiftDoctors
      sundayMorningShiftDoctors
      sundayAfternoonShiftDoctors
      sundayNightShiftDoctors
      holidayMorningShiftDoctors
      holidayAfternoonShiftDoctors
      holidayNightShiftDoctors
    }
  }
`