import BreadCrumb from "../../../components/Breadcrumb";
import {Button} from "primereact/button";
import {Formik, Form} from "formik";
import {ProgressSpinner} from "../../../components";
import React, {Suspense, useEffect, useMemo, useState} from "react";
import styles from "../ExpectationDoubleRecruitmentRecord/Style";
import Router from '@medical/routes/router';
import moment from "moment";
import './style.css';
import {Modal} from 'antd';

const areas = [
  {areaName: '午前'},
  {areaName: '午後'},
  {areaName: '夜間'},
];

const initialData = (clinics, type) => {
  let idCounter = 0;
  return clinics.map(item => (
    areas.map(area => {
      if (type === "default") {
        return {
          id: idCounter++,
          areaName: area.areaName,
          clinicName: item.name,
          clinicId: item.id,
          clinicalDepartmentId: item.clinicalDepartments.find((department) => (department.name === "CHILDREN_MEDICINE"))?.id,
          clinicalDepartment: item.clinicalDepartments.find((department) => (department.name === "CHILDREN_MEDICINE")) ? "小児科" : "",
          monday: undefined,
          tuesday: undefined,
          wednesday: undefined,
          thursday: undefined,
          friday: undefined,
          saturday: undefined,
          sunday: undefined,
          holiday: undefined,
        };
      } else {
        const dataReturn = {id: idCounter++, areaName: area?.areaName};
        if (item?.Clinic_ExpectationDoubleRecruitmentRecordPattern_clinicToClinic) {
          dataReturn.clinicName = item?.Clinic_ExpectationDoubleRecruitmentRecordPattern_clinicToClinic?.name;
          dataReturn.clinicId = item?.Clinic_ExpectationDoubleRecruitmentRecordPattern_clinicToClinic?.id;
          dataReturn.clinicalDepartment = item?.Clinic_ExpectationDoubleRecruitmentRecordPattern_clinicToClinic?.clinicalDepartments.find((department) => (department.name === "CHILDREN_MEDICINE")) ? "小児科" : "";
          dataReturn.clinicalDepartmentId = item?.Clinic_ExpectationDoubleRecruitmentRecordPattern_clinicToClinic?.clinicalDepartments.find((department) => (department.name === "CHILDREN_MEDICINE"))?.id;
        } else {
          dataReturn.clinicName = item?.name;
          dataReturn.clinicId = item?.id;
          dataReturn.clinicalDepartment = item?.clinicalDepartments.find((department) => (department.name === "CHILDREN_MEDICINE")) ? "小児科" : "";
          dataReturn.clinicalDepartmentId = item?.clinicalDepartments.find((department) => (department.name === "CHILDREN_MEDICINE"))?.id;
        }
        if (area.areaName === "午前") {
          dataReturn.monday = item?.mondayMorningShiftDoctors || undefined;
          dataReturn.tuesday = item?.tuesdayMorningShiftDoctors || undefined;
          dataReturn.wednesday = item?.wednesdayMorningShiftDoctors || undefined;
          dataReturn.thursday = item?.thursdayMorningShiftDoctors || undefined;
          dataReturn.friday = item?.fridayMorningShiftDoctors || undefined;
          dataReturn.saturday = item?.saturdayMorningShiftDoctors || undefined;
          dataReturn.sunday = item?.sundayMorningShiftDoctors || undefined;
          dataReturn.holiday = item?.holidayMorningShiftDoctors || undefined;
        } else if (area.areaName === "午後") {
          dataReturn.monday = item?.mondayAfternoonShiftDoctors || undefined;
          dataReturn.tuesday = item?.tuesdayAfternoonShiftDoctors || undefined;
          dataReturn.wednesday = item?.wednesdayAfternoonShiftDoctors || undefined;
          dataReturn.thursday = item?.thursdayAfternoonShiftDoctors || undefined;
          dataReturn.friday = item?.fridayAfternoonShiftDoctors || undefined;
          dataReturn.saturday = item?.saturdayAfternoonShiftDoctors || undefined;
          dataReturn.sunday = item?.sundayAfternoonShiftDoctors || undefined;
          dataReturn.holiday = item?.holidayAfternoonShiftDoctors || undefined;
        } else {
          dataReturn.monday = item?.mondayNightShiftDoctors || undefined;
          dataReturn.tuesday = item?.tuesdayNightShiftDoctors || undefined;
          dataReturn.wednesday = item?.wednesdayNightShiftDoctors || undefined;
          dataReturn.thursday = item?.thursdayNightShiftDoctors || undefined;
          dataReturn.friday = item?.fridayNightShiftDoctors || undefined;
          dataReturn.saturday = item?.saturdayNightShiftDoctors || undefined;
          dataReturn.sunday = item?.sundayNightShiftDoctors || undefined;
          dataReturn.holiday = item?.holidayNightShiftDoctors || undefined;
        }
        return dataReturn;
      }
    })
  )).flat();
};

const ExpectationDoubleRecruitmentRecordPatternScene = (
  {
    mutationGetExpectationDoubleRecruitmentRecordPattern,
    isUploaded,
    convertClinic,
    year,
    month,
    history,
    isCopyData,
    setIsCopyData,
    onOpenModal,
    onConfirmSubmit,
    openModal,
    setOpenModal,
    typeConfirm,
    setTypeConfirm
  }) => {
  const [clinicsState, setClinicsState] = useState([]);

  useEffect(() => {
    void handleFetchData(year, month);
  }, [year, month]);

  useEffect(() => {
    if (isCopyData) {
      let targetYear = +year;
      let targetMonth = +month;
      if (month === 1) {
        targetYear -= 1;
        targetMonth = 12;
      } else {
        targetMonth -= 1;
      }
      void handleFetchData(targetYear, targetMonth);
    }
  }, [isCopyData]);

  const handleFetchData = async (year, month) => {
    const {data: {getListExpectationDoubleRecruitmentRecordPattern}} = await mutationGetExpectationDoubleRecruitmentRecordPattern({
      variables: {targetYear: +year, targetMonth: +month},
    });
    if (getListExpectationDoubleRecruitmentRecordPattern && getListExpectationDoubleRecruitmentRecordPattern.length > 0) {
      const mergeData = convertClinic.map((item) => {
        const data = getListExpectationDoubleRecruitmentRecordPattern.find((clinic) => (clinic.Clinic_ExpectationDoubleRecruitmentRecordPattern_clinicToClinic.id === item.id));
        return data ? {...item, ...data} : item;
      });
      setClinicsState(initialData(mergeData, 'fetchData'));
    } else {
      setClinicsState(initialData(convertClinic, 'default'));
    }
    setIsCopyData(false);
  };

  const currentDate = useMemo(() => moment().year(year).month(month - 1).startOf('month').toISOString(), [year, month]);
  const date = useMemo(() => moment(currentDate), [currentDate]);
  const prevMonth = useMemo(() => moment(date).subtract(1, 'month'), [date]);
  const nextMonth = useMemo(() => moment(date).add(1, 'month'), [date]);

  const isPreviousDisabled = useMemo(() => date.isSameOrBefore(moment().subtract(1, 'years'), 'month'), [date]);
  const isNextDisabled = useMemo(() => date.isSameOrAfter(moment().add(6, 'quarters'), 'month'), [date]);

  const handleChangeInput = (setFieldValue, type, e, option) => {
    let val = parseInt(e.target.value, 10);
    if (val < 1) val = 1;
    if (val > 9) val = 9;
    setFieldValue(`data[${option.id}].${type}`, +val);
  };

  return (
    <div key={Math.random()}>
      <div className={'staff-header'}>
        <div className={'staff-title'} style={{margin: '0', marginTop: '20px'}}>
          二診目希望パターン登録
          <BreadCrumb
            items={[
              {title: '二診目希望登録', to: Router.get(Router.staffExpectationDoubleRecruitmentRecord, {year, month})},
              {title: '二診目希望パターン登録'},
            ]}
          />
        </div>
      </div>
      <div className='container is-max'>
        {isUploaded && <div style={styles.loading}><ProgressSpinner/></div>}
        <div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '40px'}}>
            <Button
              icon='pi pi-chevron-left'
              disabled={isPreviousDisabled}
              onClick={async () => {
                if (!isPreviousDisabled) {
                  await history.push({
                    pathname: Router.get(Router.staffExpectationDoubleRecruitmentRecordPattern, {
                      year: prevMonth.year(),
                      month: prevMonth.format('MM')
                    })
                  });
                }
              }}
            />
            <div style={styles.title}>{moment(currentDate).format('YYYY年MM月')}</div>
            <Button
              icon='pi pi-chevron-right'
              disabled={isNextDisabled}
              onClick={async () => {
                if (!isNextDisabled) {
                  await history.push({
                    pathname: Router.get(Router.staffExpectationDoubleRecruitmentRecordPattern, {
                      year: nextMonth.year(),
                      month: nextMonth.format('MM')
                    })
                  });
                }
              }}
            />
          </div>
          <div style={{marginBottom: '20px'}}>
            <Button
              type={'button'}
              className='p-button-secondary'
              label={'先月のデータをコピー'}
              onClick={() => {
                setOpenModal(true);
                setTypeConfirm('copy');
              }}
            />
          </div>
          <Formik
            initialValues={{data: clinicsState}}
            onSubmit={onOpenModal}
            render={(formikProps) => {
              const {values, setFieldValue, handleSubmit} = formikProps;
              return (
                <div>
                  <Modal
                    onCancel={() => setOpenModal(false)}
                    visible={openModal}
                    title={<span style={{fontWeight: '500'}}>確認</span>}
                    centered
                    footer={
                      <div style={{textAlign: 'right'}}>
                        <Button
                          icon='pi pi-check'
                          style={{marginRight: '8px'}}
                          onClick={() => {
                            if (typeConfirm === 'copy') {
                              setIsCopyData(true);
                              setOpenModal(false);
                            } else if (typeConfirm === 'submit') {
                              onConfirmSubmit();
                            }
                          }}
                          label={'はい'}
                        />
                        <Button
                          icon='pi pi-times'
                          className='p-button-secondary'
                          onClick={() => setOpenModal(false)}
                          label={'いいえ'}
                        />
                      </div>
                    }
                  >
                    <span>データを更新しますか？</span>
                  </Modal>
                  <div className={'box pt-3'} style={{overflowX: 'auto'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                      <p style={{fontSize: '12px'}}>表示項目説明</p>
                      <p style={{fontSize: '12px', color: 'rgb(255, 0, 0)'}}>
                        ＊祝日の場合祝日の値が優先されます。
                      </p>
                    </div>
                    <Suspense fallback={<ProgressSpinner/>}>
                      <div className={'expectation-double-recruitment-record-pattern-data-table'}>
                        <table>
                          <thead>
                          <tr>
                            <th className={'clinicName'}>拠点</th>
                            <th className={'clinicalDepartment'}>診療科</th>
                            <th className={'areaName'}>時間帯</th>
                            <th className={'monday'}>(月)</th>
                            <th className={'tuesday'}>(火)</th>
                            <th className={'wednesday'}>(水)</th>
                            <th className={'thursday'}>(木)</th>
                            <th className={'friday'}>(金)</th>
                            <th className={'saturday'}>(土)</th>
                            <th className={'sunday'}>(日)</th>
                            <th className={'holiday'}>(祝)</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            values?.data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.clinicName}</td>
                                  <td>{item.clinicalDepartment}</td>
                                  <td>{item.areaName}</td>
                                  <td>
                                    <input
                                      onChange={(e) => handleChangeInput(setFieldValue, 'monday', e, item)}
                                      value={values?.data[item.id]?.monday}
                                      type={"number"}
                                      step={1}
                                      max={9}
                                      min={1}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      onChange={(e) => handleChangeInput(setFieldValue, 'tuesday', e, item)}
                                      value={values?.data[item.id]?.tuesday}
                                      type={"number"}
                                      step={1}
                                      max={9}
                                      min={1}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      onChange={(e) => handleChangeInput(setFieldValue, 'wednesday', e, item)}
                                      value={values?.data[item.id]?.wednesday}
                                      type={"number"}
                                      step={1}
                                      max={9}
                                      min={1}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      onChange={(e) => handleChangeInput(setFieldValue, 'thursday', e, item)}
                                      value={values?.data[item.id]?.thursday}
                                      type={"number"}
                                      step={1}
                                      max={9}
                                      min={1}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      onChange={(e) => handleChangeInput(setFieldValue, 'friday', e, item)}
                                      value={values?.data[item.id]?.friday}
                                      type={"number"}
                                      step={1}
                                      max={9}
                                      min={1}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      onChange={(e) => handleChangeInput(setFieldValue, 'saturday', e, item)}
                                      value={values?.data[item.id]?.saturday}
                                      type={"number"}
                                      step={1}
                                      max={9}
                                      min={1}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      onChange={(e) => handleChangeInput(setFieldValue, 'sunday', e, item)}
                                      value={values?.data[item.id]?.sunday}
                                      type={"number"}
                                      step={1}
                                      max={9}
                                      min={1}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      onChange={(e) => handleChangeInput(setFieldValue, 'holiday', e, item)}
                                      value={values?.data[item.id]?.holiday}
                                      type={"number"}
                                      step={1}
                                      max={9}
                                      min={1}
                                    />
                                  </td>
                                </tr>
                              )
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                    </Suspense>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '20px'}}>
                    <Button
                      type={'submit'}
                      onClick={() => {
                        handleSubmit();
                      }}
                      className='reflect-button'
                      disabled={isUploaded}
                      label={'確定する'}
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};


export default ExpectationDoubleRecruitmentRecordPatternScene;