import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useCustom} from "../../../provider/context";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
  CLINICS_CONNECTION,
  CREATE_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN,
  GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN,
  GET_LIST_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN
} from "./ExpectationDoubleRecruitmentRecordPattern.graphql";
import moment from "moment/moment";
import {ErrorComponent, ProgressSpinner} from "../../../components";
import ExpectationDoubleRecruitmentRecordPatternScene from "./ExpectationDoubleRecruitmentRecordPatternScene";
import Router from "../../../routes/router";

const ExpectationDoubleRecruitmentRecordPattern = (
  {
    history,
    match: {
      path,
      params: {year = moment().year(), month = moment().month()},
    },
  }
) => {
  const [{popup}] = useCustom()
  const [state, setState] = useState({
    isUploaded: false,
  })
  const [isCopyData, setIsCopyData] = useState(false);
  const {
    loading: clinicsLoading,
    error: clinicsError,
    data: clinics,
    refetch: clinicRefetch
  } = useQuery(CLINICS_CONNECTION, {
    fetchPolicy: 'network-only',
  })
  const [openModal, setOpenModal] = useState(false);
  const [dataSubmit, setDataSubmit] = useState([])
  const [typeConfirm, setTypeConfirm] = useState('');

  const [createExpectationDoubleRecruitmentRecordPattern] = useMutation(CREATE_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN)
  const [mutationGetExpectationDoubleRecruitmentRecordPattern] = useMutation(GET_LIST_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN)

  const currentDate = useMemo(
    () =>
      moment()
        .year(year)
        .month(month - 1)
        .startOf('month')
        .toISOString(),
    [year, month]
  )

  if (clinicsLoading) return <ProgressSpinner/>
  if (clinicsError) return <ErrorComponent
    error={clinicsError}/>

  const {
    clinicsConnection: {
      edges,
    },
  } = clinics

  const convertClinic = (clinics) => (clinics.map(clinic => {
    return {
      ...clinic.node,
    }
  }))

  const onOpenModal = ({data}) => {
    setOpenModal(true);
    setDataSubmit(data);
    setTypeConfirm('submit')
  }
  const onConfirmSubmit = async () => {
    await onSubmit({data: dataSubmit})
  }
  const onSubmit = async ({data}) => {
    setState({
      isUploaded: true,
    })
    try {
      const convertData = data.map((item) => {
        const returnData= {
          clinic: item?.clinicId,
          clinicalDepartment: item?.clinicalDepartmentId,
        }
        if (item.areaName === "午前") {
          returnData.mondayMorningShiftDoctors = +item?.monday ?? null
          returnData.tuesdayMorningShiftDoctors =  +item?.tuesday ?? null
          returnData.wednesdayMorningShiftDoctors=  +item?.wednesday ?? null
          returnData.thursdayMorningShiftDoctors=  +item?.thursday ?? null
          returnData.fridayMorningShiftDoctors=  +item?.friday ?? null
          returnData.saturdayMorningShiftDoctors=  +item?.saturday ?? null
          returnData.sundayMorningShiftDoctors=  +item?.sunday ?? null
          returnData.holidayMorningShiftDoctors=  +item?.holiday ?? null
        } else if (item.areaName === "午後") {
          returnData.mondayAfternoonShiftDoctors=  +item?.monday ?? null
          returnData.tuesdayAfternoonShiftDoctors=  +item?.tuesday ?? null
          returnData.wednesdayAfternoonShiftDoctors=  +item?.wednesday ?? null
          returnData.thursdayAfternoonShiftDoctors=  +item?.thursday ?? null
          returnData.fridayAfternoonShiftDoctors= +item?.friday ?? null
          returnData.saturdayAfternoonShiftDoctors=  +item?.saturday ?? null
          returnData.sundayAfternoonShiftDoctors=  +item?.sunday ?? null
          returnData.holidayAfternoonShiftDoctors=  +item?.holiday ?? null
        } else {
          returnData.mondayNightShiftDoctors=  +item?.monday ?? null
          returnData.tuesdayNightShiftDoctors=  +item?.tuesday ?? null
          returnData.wednesdayNightShiftDoctors=  +item?.wednesday ?? null
          returnData.thursdayNightShiftDoctors=  +item?.thursday ?? null
          returnData.fridayNightShiftDoctors=  +item?.friday ?? null
          returnData.saturdayNightShiftDoctors=  +item?.saturday ?? null
          returnData.sundayNightShiftDoctors=  +item?.sunday ?? null
          returnData.holidayNightShiftDoctors=  +item?.holiday ?? null
        }
        return returnData
      })
      const reduceArray = Object.values(
        convertData.reduce((acc, item) => {
          const key = `${item.clinic}-${item.clinicalDepartment}`;
          if (!acc[key]) {
            acc[key] = { ...item };
          } else {
            Object.assign(acc[key], item);
          }
          return acc;
        }, {})
      );
      const rs = await createExpectationDoubleRecruitmentRecordPattern(
        {
          variables: {
            data: reduceArray,
            targetMonth: +month,
            targetYear: +year,
          }
        }
      )
      if (rs) {
        setState({
          isUploaded: false,
        })
        popup.success('成功　保存しました。')
        await history.push({
          pathname: Router.get(Router.staffExpectationDoubleRecruitmentRecord,
            {
              year: moment(currentDate).year(),
              month: moment(currentDate).format('MM'),
            }
          )
        })
      } else {
        setState({
          isUploaded: false,
        })
        popup.error('エラー　エラーが発生しました。再度ご確認ください。')
      }
    } catch (error) {
      setState({
        isUploaded: false,
      })
      console.error(error)
      popup.clear()
      popup.error(error)
    }
  }

  return (
    <ExpectationDoubleRecruitmentRecordPatternScene
      mutationGetExpectationDoubleRecruitmentRecordPattern={mutationGetExpectationDoubleRecruitmentRecordPattern}
      isUploaded={state.isUploaded}
      convertClinic={convertClinic(edges)}
      year={year}
      month={month}
      isCopyData={isCopyData}
      setIsCopyData={setIsCopyData}
      onOpenModal={onOpenModal}
      onConfirmSubmit={onConfirmSubmit}
      openModal={openModal}
      setOpenModal={setOpenModal}
      typeConfirm={typeConfirm}
      setTypeConfirm={setTypeConfirm}
      history={history}/>

  );
};

export default ExpectationDoubleRecruitmentRecordPattern;