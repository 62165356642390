import { useMutation } from '@apollo/react-hooks'
import { GET_POST_PRE_SIGNED_URL } from '@medical/components/Forms/UploadImage/UploadImage.graphql'
import { MAX_FILE_SIZE } from '@medical/constant'
import types from '@medical/constant/typeTransports'
import uploadImage from '@medical/libs/uploadImage'
import AutoComplete from '@medical/pages/doctor/Transport/TransportRegister/TransportRegisterAutoComplete'
import { useCustom } from '@medical/provider/context'
import { DatePicker, Input, InputNumber, Modal } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import { ProgressSpinner } from '@medical/components'

const TransportPeriodicSceneMobile = ({
  index,
  formikProps,
  showModal,
  visible,
  hideModal,
  remove,
  slipt,
  clinic,
  newTypes,
  setCheckUploadFile,
  checkUploadFile,
}) => {
  const [{ i18n, popup }] = useCustom()
  const [loading, setLoading] = useState(false)
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL)
  const { setFieldValue, handleChange, values } = formikProps
  const disable = !!(
    values.splits[index].category.value === '駐車代' ||
    values.splits[index].category.value === '宿泊代'
  )

  return (
    <>
      <div className='mobile-report' style={{ marginBottom: '20px' }}>
        <div className='tr' key={index}>
          <div className='th' style={{ width: '50%' }}>
            {i18n.t('transportApplication.clinicName')}
          </div>
          <div className='td'>
            <Dropdown
              {...formikProps}
              value={values.splits[index].clinic}
              name={`splits[${index}].clinic`}
              placeholder='クリニックを選択'
              optionLabel='name'
              valueLabel='id'
              autoWidth
              style={{ width: '100%' }}
              options={clinic || []}
              onChange={e => {
                setFieldValue(`splits[${index}].clinic`, e.value)
              }}
            />
            <ErrorMessage
              name={`splits[${index}].clinic`}
              component='div'
              className='signup-alert'
            />
          </div>
          <div className='th' style={{ width: '50%' }}>
            {i18n.t('transportApplication.categoryApplication')}
          </div>
          <div className='td'>
            <Dropdown
              {...formikProps}
              value={values.splits[index].category}
              name={`splits[${index}].category`}
              placeholder='申請区分を選択'
              optionLabel='description'
              valueLabel='value'
              style={{ width: '100%' }}
              options={newTypes}
              onChange={e => {
                setFieldValue(`splits[${index}].category`, e.value)
                setFieldValue(`splits[${index}].maxCost`, e.maxCost)
              }}
            />

            <ErrorMessage
              name={`splits[${index}].category`}
              component='div'
              className='signup-alert'
            />
          </div>
          <div className='th' style={{ width: '50%' }}>
            {i18n.t('transportApplication.routeURL')}
            <Link to='#' style={{ color: '#40a9ff' }} onClick={showModal}>
              ※
            </Link>
            <Modal
              title='経路URLについて'
              className='modal-antd'
              closable={false}
              visible={visible}
              okButtonProps={{
                style: {
                  display: 'none',
                },
              }}
              onCancel={hideModal}
              cancelText={i18n.t('main.close')}
            >
              <span className='font-weight-600'>
                1. 電車/バスの定期券をご申請いただく場合
              </span>
              <br />
              以下のサイトで勤務拠点の片道の最短/最安経路を検索し、検索結果が表示されたURLをご提出ください。
              <br />
              <Link
                to='#'
                onClick={() => {
                  window.open('https://transit.yahoo.co.jp/', '_blank')
                }}
              >
                (https://transit.yahoo.co.jp/)
              </Link>
              <br />
              <span className='font-weight-600'>
                2. 拠点間移動にかかった交通費をご申請いただく場合
              </span>
              <br />
              以下のサイトで移動した距離の最短/最安経路を検索し、検索結果が表示されたURLをご提出ください。
              移動元拠点～移動先拠点まで利用する全ての交通機関を指定し、経路検索を行ってください。
              <br />
              <Link
                to='#'
                onClick={() => {
                  window.open('https://transit.yahoo.co.jp/', '_blank')
                }}
              >
                (https://transit.yahoo.co.jp/)
              </Link>
              <br />
              <span className='font-weight-600'>
                3. タクシー/マイカー通勤/その他公共交通機関以外をご利用の場合
              </span>
              <br />
              以下のサイトで移動した距離を経路検索し、その検索結果のURLをご提出ください。
              <br />
              <Link
                to='#'
                onClick={() => {
                  window.open(
                    'https://www.google.com/maps/dir///@35.888145,139.716763,18z?hl=ja',
                    '_blank'
                  )
                }}
              >
                (https://www.google.com/maps/dir///@35.888145,139.716763,18z?hl=ja)
              </Link>
            </Modal>
          </div>
          <div className='td'>
            <Input
              allowClear
              className='color-url'
              placeholder='https://www.navitime.co.jp/'
              name={`splits[${index}].routeUrl`}
              value={values.splits[index].routeUrl}
              onChange={handleChange}
              disabled={!!(values.splits[index].category.value === '駐車代')}
            />
            <ErrorMessage
              name={`splits[${index}].routeUrl`}
              component='div'
              className='signup-alert'
            />
          </div>
          <div className='th' style={{ width: '50%' }}>
            {i18n.t('transportApplication.date')}
          </div>
          <div className='td'>
            <DatePicker
              style={{ width: '100%' }}
              placeholder={i18n.t('transportApplication.date')}
              format='YYYY年MM月DD日'
              locale={locale}
              allowClear={false}
              value={
                values.splits[index].startTime
                  ? moment(
                      new Date(values.splits[index].startTime),
                      'YYYY年MM月DD日'
                    )
                  : null
              }
              onChange={value => {
                setFieldValue(
                  `splits[${index}].startTime`,
                  moment
                    .utc(value)
                    .startOf('day')
                    .toISOString()
                )
                setFieldValue(
                  `splits[${index}].endTime`,
                  moment
                    .utc(value)
                    .endOf('day')
                    .toISOString()
                )
              }}
            />
            <ErrorMessage
              name={`splits[${index}].endTime` && `splits[${index}].startTime`}
              component='div'
              className='signup-alert'
            />
          </div>
          <div className='th' style={{ width: '50%' }}>
            {i18n.t('transportApplication.travelSection')}
          </div>
          <div className='td'>
            <Input.Group compact>
              <Input
                allowClear
                style={{
                  width: '45%',
                  textAlign: 'center',
                }}
                placeholder='東京駅'
                name={`splits[${index}].travelSectionStart`}
                value={values.splits[index].travelSectionStart}
                onChange={handleChange}
                disabled={disable}
              />

              <Input
                className='site-input-split'
                style={{
                  width: '10%',
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder='~'
                disabled
              />
              <Input
                allowClear
                className='site-input-right'
                style={{
                  width: '45%',
                  textAlign: 'center',
                }}
                placeholder='葛西駅'
                name={`splits[${index}].travelSectionEnd`}
                value={values.splits[index].travelSectionEnd}
                onChange={handleChange}
                disabled={disable}
              />
              <ErrorMessage
                name={
                  `splits[${index}].travelSectionStart` &&
                  `splits[${index}].travelSectionEnd`
                }
                component='div'
                className='signup-alert'
              />
            </Input.Group>
          </div>
          <div className='th' style={{ width: '50%' }}>
            {i18n.t('transportApplication.cost')}
          </div>
          <div className='td' style={{ width: '50%' }}>
            <InputNumber
              placeholder='￥ 10,000'
              formatter={value =>
                `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              type='tel'
              allowClear
              step={5}
              min={1}
              name={`splits[${index}].cost`}
              value={values.splits[index].cost}
              onChange={e => {
                setFieldValue(`splits[${index}].cost`, e)
              }}
              disabled={!!(values.splits[index].category.value === 'CAR')}
            />
            <ErrorMessage
              name={`splits[${index}].cost`}
              component='div'
              className='signup-alert'
            />
          </div>
          <div className='th' style={{ width: '50%' }}>
            {i18n.t('transportApplication.receipt')}
          </div>
          <div className='td' style={{ width: '50%' }}>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <Dropzone
                onDrop={async files => {
                  if (files.length > 0) {
                    setLoading(true)
                    setFieldValue(`splits[${index}].slipt`, true)
                    const {
                      data: postPreSignedUrl,
                    } = await getPostPreSignedUrl({
                      variables: {
                        fileName: files[0].name,
                        mimetype: files[0].type,
                      },
                    })
                    const key = await uploadImage(
                      i18n,
                      popup,
                      postPreSignedUrl,
                      files[0]
                    )
                    if (key) {
                      setFieldValue(`splits[${index}].file`, key)
                    }
                    setCheckUploadFile(prev => prev.filter(msg => msg.index !== index))
                    setLoading(false)
                  }
                }}
                accept={['image/jpeg', 'image/png', 'application/pdf']}
                maxSize={MAX_FILE_SIZE}
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({})}>
                    <Button
                      label={i18n.t('doctor.uploadFile.submit')}
                      icon='pi pi-cloud-upload'
                      className='p-button-secondary sp-button'
                    />
                    <input {...getInputProps()} />
                    <aside>{slipt.file}</aside>
                    {
                      checkUploadFile.length > 0 && !!(checkUploadFile.find(msg => msg.index === index)) && (
                        <div style={{color: '#f84e51', fontWeight: '600'}}>
                          {checkUploadFile.find(msg => msg.index === index).message}
                        </div>
                      )
                    }
                  </div>
                )}
              </Dropzone>
            )}
          </div>
          <div className='th' style={{ width: '50%' }}>
            {i18n.t('transportApplication.comment')}
          </div>
          <div className='td'>
            <Input.TextArea
              placeholder={i18n.t('transportApplication.comment')}
              size='large'
              name={`splits[${index}].comment`}
              value={values.splits[index].comment}
              autoSize={{
                minRows: 1,
                maxRows: 2,
              }}
              onChange={handleChange}
            />
            <ErrorMessage
              name={`splits[${index}].comment`}
              component='div'
              className='signup-alert'
            />
          </div>
          <div className='td' style={{ textAlign: 'center' }}>
            <Button
              icon='pi pi-trash'
              onClick={() => {
                if (values.splits.length === 1) {
                  setFieldValue(`splits[${index}].routeUrl`, '')
                  setFieldValue(`splits[${index}].category`, '')
                  setFieldValue(`splits[${index}].startTime`, '')
                  setFieldValue(`splits[${index}].endTime`, '')
                  setFieldValue(`splits[${index}].travelSectionStart`, '')
                  setFieldValue(`splits[${index}].travelSectionEnd`, '')
                  setFieldValue(`splits[${index}].cost`, '')
                  setFieldValue(`splits[${index}].file`, '')
                } else {
                  remove(index)
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default TransportPeriodicSceneMobile
